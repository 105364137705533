import { createJobExperience } from './job-experience'
import { createEducation } from './education'
import { createPrivacies } from './marketplace/privacies'
import { createSalary } from './salary'
import { createFocus } from './focus'

export const createCandidate = (candidate, marketplace) => ({
  id: candidate.id,
  filledAt: candidate.filledAt,
  pretensionSalary: candidate.pretensionSalary,
  pretensionCurrency: candidate.pretensionCurrency,
  pretensionPeriodicity: candidate.pretensionPeriodicity,
  pretensionContract: candidate.pretensionContract,
  career: {
    id: candidate.focus?.career?.id,
    name: candidate.focus?.career?.name
  },
  comunicationPreferences: candidate.comunicationPreferences,
  cvUrl: candidate.cvUrl,
  englishLevel: candidate.englishLevel,
  email: candidate.email,
  experienceTime: candidate.experienceTime,
  focus: createFocus(candidate.focus),
  linkedinUrl: candidate.linkedinUrl,
  portfolios: candidate.portfolios,
  location: {
    id: candidate.livesIn?.id,
    country: candidate.livesIn?.country,
    countryCode: candidate.livesIn?.countryCode,
    adminName: candidate.livesIn?.adminName,
    name: candidate.livesIn?.name
  },
  name: candidate.name,
  phone: candidate.phone,
  phoneCountry: candidate.phoneCountry,
  photoUrl: candidate.photoUrl,
  skills: candidate.skills,
  assessmentResponded: candidate.assessmentResponded,
  jobExperiences: (candidate.experiences || []).map(createJobExperience),
  educations: (candidate.educations || []).map(createEducation),
  salaries: (candidate.salaries || []).map(createSalary),
  signupCompleted: candidate.signupCompleted,
  internationalProfileCompleted: candidate.internationalProfileCompleted,
  preferredLanguage: candidate.preferredLanguage,
  diversities: candidate.diversities,
  disabilities: candidate.disabilities,
  visibleOnMarketplace: candidate.visibleOnMarketplace,
  marketplace: {
    hasAccount: marketplace?.hasAccount,
    invitable: marketplace?.invitable,
    uninvitableReason: marketplace?.uninvitableReason,
    privacies: createPrivacies(marketplace?.privacies),
    pretensionSalary: marketplace?.pretensionSalary || findCandidatePretensionSalary(candidate)?.value,
    pretensionContract: marketplace?.pretensionContract || findCandidatePretensionSalary(candidate)?.contract
  },
  origin: candidate.origin
})

const findCandidatePretensionSalary = (candidate) => {
  return candidate.salaries?.find(salary => salary.kind === 'pretension' && salary.currency === 'BRL')
}
